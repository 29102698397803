import _fontManager from "@samuelmeuli/font-manager";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

(function (global, factory) {
  exports = factory(_fontManager);
})(exports, function (fontManager) {
  'use strict';

  var FontPicker = function () {
    function FontPicker(apiKey, defaultFamily, _a, onChange) {
      if (defaultFamily === void 0) {
        defaultFamily = fontManager.FONT_FAMILY_DEFAULT;
      }

      var _b = _a.pickerId,
          pickerId = _b === void 0 ? fontManager.OPTIONS_DEFAULTS.pickerId : _b,
          _c = _a.families,
          families = _c === void 0 ? fontManager.OPTIONS_DEFAULTS.families : _c,
          _d = _a.categories,
          categories = _d === void 0 ? fontManager.OPTIONS_DEFAULTS.categories : _d,
          _e = _a.scripts,
          scripts = _e === void 0 ? fontManager.OPTIONS_DEFAULTS.scripts : _e,
          _f = _a.variants,
          variants = _f === void 0 ? fontManager.OPTIONS_DEFAULTS.variants : _f,
          _g = _a.filter,
          filter = _g === void 0 ? fontManager.OPTIONS_DEFAULTS.filter : _g,
          _h = _a.limit,
          limit = _h === void 0 ? fontManager.OPTIONS_DEFAULTS.limit : _h,
          _j = _a.sort,
          sort = _j === void 0 ? fontManager.OPTIONS_DEFAULTS.sort : _j;

      if (onChange === void 0) {
        onChange = function () {};
      }

      (this || _global).expanded = false;
      (this || _global).closeEventListener = (this || _global).closeEventListener.bind(this || _global);
      (this || _global).toggleExpanded = (this || _global).toggleExpanded.bind(this || _global);
      var options = {
        pickerId: pickerId,
        families: families,
        categories: categories,
        scripts: scripts,
        variants: variants,
        filter: filter,
        limit: limit,
        sort: sort
      };
      (this || _global).fontManager = new fontManager.FontManager(apiKey, defaultFamily, options, onChange);
      this.generateUI(sort);
    }

    FontPicker.prototype.generateUI = function (sort) {
      var _this = this || _global;

      var selectorSuffix = (this || _global).fontManager.selectorSuffix;
      var pickerId = "font-picker" + selectorSuffix;
      (this || _global).fontPickerDiv = document.getElementById(pickerId);

      if (!(this || _global).fontPickerDiv) {
        throw Error("Missing div with id=\"" + pickerId + "\"");
      }

      var dropdownButton = document.createElement("button");
      dropdownButton.classList.add("dropdown-button");
      dropdownButton.onclick = (this || _global).toggleExpanded;
      dropdownButton.onkeypress = (this || _global).toggleExpanded;
      dropdownButton.type = "button";

      (this || _global).fontPickerDiv.appendChild(dropdownButton);

      (this || _global).dropdownFamily = document.createElement("p");
      (this || _global).dropdownFamily.textContent = (this || _global).fontManager.getActiveFont().family;

      (this || _global).dropdownFamily.classList.add("dropdown-font-family");

      dropdownButton.appendChild((this || _global).dropdownFamily);
      var dropdownIcon = document.createElement("p");
      dropdownIcon.classList.add("dropdown-icon", "loading");
      dropdownButton.appendChild(dropdownIcon);

      (this || _global).fontManager.init().then(function (fontMap) {
        var fonts = Array.from(fontMap.values());

        if (sort === "alphabet") {
          fonts.sort(function (font1, font2) {
            return font1.family.localeCompare(font2.family);
          });
        }

        _this.generateFontList(fonts);

        dropdownIcon.classList.replace("loading", "finished");
      })["catch"](function (err) {
        dropdownIcon.classList.replace("loading", "error");
        console.error("Error trying to fetch the list of available fonts");
        console.error(err);
      });
    };

    FontPicker.prototype.generateFontList = function (fonts) {
      var _this = this || _global;

      (this || _global).ul = document.createElement("ul");

      (this || _global).ul.classList.add("font-list");

      fonts.forEach(function (font) {
        _this.addFontLi(font);
      });

      (this || _global).fontPickerDiv.appendChild((this || _global).ul);

      var activeFontFamily = (this || _global).fontManager.getActiveFont().family;

      var activeFontId = fontManager.getFontId(activeFontFamily);
      var fontButtonId = "font-button-" + activeFontId + (this || _global).fontManager.selectorSuffix;
      (this || _global).activeFontButton = document.getElementById(fontButtonId);

      if ((this || _global).activeFontButton) {
        (this || _global).activeFontButton.classList.add("active-font");
      } else {
        console.error("Could not find font button with ID (" + fontButtonId + ")");
      }
    };

    FontPicker.prototype.addFontLi = function (font, listIndex) {
      var _this = this || _global;

      var fontId = fontManager.getFontId(font.family);
      var li = document.createElement("li");
      li.classList.add("font-list-item");
      var fontButton = document.createElement("button");
      fontButton.type = "button";
      fontButton.id = "font-button-" + fontId + (this || _global).fontManager.selectorSuffix;
      fontButton.classList.add("font-button");
      fontButton.textContent = font.family;

      var onActivate = function () {
        _this.toggleExpanded();

        _this.setActiveFont(font.family);
      };

      fontButton.onclick = onActivate;
      fontButton.onkeypress = onActivate;
      li.appendChild(fontButton);

      if (listIndex) {
        (this || _global).ul.insertBefore(li, (this || _global).ul.children[listIndex]);
      } else {
        (this || _global).ul.appendChild(li);
      }
    };

    FontPicker.prototype.closeEventListener = function (e) {
      var targetEl = e.target;
      var fontPickerEl = document.getElementById("font-picker" + (this || _global).fontManager.selectorSuffix);

      while (true) {
        if (targetEl === fontPickerEl) {
          return;
        }

        if (targetEl.parentNode) {
          targetEl = targetEl.parentNode;
        } else {
          this.toggleExpanded();
          return;
        }
      }
    };

    FontPicker.prototype.toggleExpanded = function () {
      if ((this || _global).expanded) {
        (this || _global).expanded = false;

        (this || _global).fontPickerDiv.classList.remove("expanded");

        document.removeEventListener("click", (this || _global).closeEventListener);
      } else {
        (this || _global).expanded = true;

        (this || _global).fontPickerDiv.classList.add("expanded");

        document.addEventListener("click", (this || _global).closeEventListener);
      }
    };

    FontPicker.prototype.getFonts = function () {
      return (this || _global).fontManager.getFonts();
    };

    FontPicker.prototype.addFont = function (fontFamily, index) {
      if (Array.from((this || _global).fontManager.getFonts().keys()).includes(fontFamily)) {
        throw Error("Did not add font to font picker: Font family \"" + fontFamily + "\" is already in the list");
      }

      (this || _global).fontManager.addFont(fontFamily, true);

      var font = (this || _global).fontManager.getFonts().get(fontFamily);

      if (font) {
        this.addFontLi(font, index);
      } else {
        console.error("Font \"" + fontFamily + "\" is missing in font list");
      }
    };

    FontPicker.prototype.removeFont = function (fontFamily) {
      (this || _global).fontManager.removeFont(fontFamily);

      var fontId = fontManager.getFontId(fontFamily);
      var fontButton = document.getElementById("font-button-" + fontId + (this || _global).fontManager.selectorSuffix);

      if (fontButton) {
        var fontLi = fontButton.parentElement;
        fontButton.remove();

        if (fontLi) {
          fontLi.remove();
        }
      } else {
        throw Error("Could not remove font from font picker: Font family \"" + fontFamily + "\" is not in the list");
      }
    };

    FontPicker.prototype.getActiveFont = function () {
      return (this || _global).fontManager.getActiveFont();
    };

    FontPicker.prototype.setActiveFont = function (fontFamily) {
      (this || _global).fontManager.setActiveFont(fontFamily);

      var fontId = fontManager.getFontId(fontFamily);
      (this || _global).dropdownFamily.textContent = fontFamily;

      if ((this || _global).activeFontButton) {
        (this || _global).activeFontButton.classList.remove("active-font");

        (this || _global).activeFontButton = document.getElementById("font-button-" + fontId + (this || _global).fontManager.selectorSuffix);

        (this || _global).activeFontButton.classList.add("active-font");
      } else {
        console.error("`activeFontButton` is undefined");
      }
    };

    FontPicker.prototype.setOnChange = function (onChange) {
      (this || _global).fontManager.setOnChange(onChange);
    };

    return FontPicker;
  }();

  return FontPicker;
});

export default exports;